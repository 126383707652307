<template>
  <div class="payments"><payments-report /></div>
</template>

<script>
import PaymentsReport from "@/components/Reports/Sales/PaymentsReport.vue";

export default {
  name: "Payments",
  components: { PaymentsReport },
};
</script>